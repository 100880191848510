@font-face {
  font-family: 'Josefin Sans';
  src: local('Josefin Sans'), url(./fonts/JosefinSans-VariableFont_wght.ttf) format('woff');
}

.App {
  text-align: center;
}

.bg-purple {
  background-color: #6627a7;
}

.bg-grey {
  background-color: #343434
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.vh-130 {
  height: 130vh;
}

.vh-120 {
  height: 120vh;
}

.vh-100 {
  height: 100vh;
}

.vh-90 {
  height: 90vh;
}

.vh-80 {
  height: 80vh;
}

.vh-70 {
  height: 70vh;
}

.vh-60 {
  height: 60vh;
}

.vh-30 {
  height: 30vh;
}

.vh-20 {
  height: 20vh;
}


.h-100 {
  height: 100%;
}

.h-body {
  height: calc(100% - 66px);
}

.h-90 {
  height: 90%;
}

.h-80 {
  height: 80%;
}

.h-50 {
  height: 500%;
}

.wm-600px{
  max-width: 600px;
}

.w-100 {
  width: 100%;
}

.w-90 {
  width: 90%;
}

.w-80 {
  width: 80%;
}

.w-75 {
  width: 50%;
}

.w-50 {
  width: 50%;
}

.row {
  display: flex;
  flex-direction: row;
}

.col {
  display: flex;
  flex-direction: column;
}

nav {
  margin-top: 20px;
  width: 800px;
}

.mt-auto {
  margin-top: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mb-auto {
  margin-bottom: auto;
}

.mr-auto {
  margin-right: auto;
}

.mx-auto {
  margin-right: auto;
  margin-left: auto;
}

.ml-auto {
  margin-left: auto;
}

.m-auto {
  margin: auto;
}

.m-0px {
  margin: 0px;
}


.nav-link {
  line-height: 24px;
  color: #fff;
  text-decoration: none;
  margin-top: 12px;
  padding: auto;
  font-family: "Bitter", Sans-serif;
  font-size: 16px !important;
  font-weight: 400;
}

.sm-container {
  width: 360px;
}

.container-400px {
  width: 400px;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.p-10 {
  padding: 10px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto, auto);
}

.grid-container-2x2 {
  display: grid;
  grid-template-columns: repeat(2, auto);
}

.grid-container-4x4 {
  display: grid;
  grid-template-columns: repeat(4, auto);
}

.bg-pink {
  background-color: #f26989;
}

.bg-white {
  background-color: #fff;
}


@media only screen and (max-width: 768px) {

  .m-mr-auto {
    margin-right: auto;
  }
  
  .m-ml-auto {
    margin-left: auto;
  }

  .home-item2 {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .home-item1 {
    background-color: #000;
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 2;
    grid-row-end: 2;
  }
}

@media only screen and (min-width: 768px) {

  .d-w-50{
    width:50%;
  }

  .splash-screen-img {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    height: 50.63%;
    max-width: 800px;
  }
  
  .splash-screen-text {
    max-width: 730px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  
  .home-item1 {
    width: 50%;
    background-color: #000;
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .home-item2 {
    width: 50%;
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .array-img-container {
    width: 300px;
    height: 300px;
  }

  .array-img {
   margin: auto;
   max-width: 300px;
   max-height: 300px;
  }

  .sm-p{
    max-width: 520px;
  }


  .md-div{
    max-width: 720px;
  }
}

.content {
  margin-left: auto;
  margin-right: auto;
}

.big-content {
  margin-left: auto;
  margin-right: auto;
  max-width: 1140px;
}

@media only screen and (max-width: 768px) {

  .lesson-menu {
    text-align: center;
    list-style: none;
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    padding-left: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .lesson-link {
    margin-left: auto;
    margin-right: auto;
    line-height: 24px;
    color: #fff;
    text-decoration: none;
    margin-top: 12px;
    padding: auto;
    font-family: "Bitter", Sans-serif;
    font-size: 16px !important;
    font-weight: 400;
  }

  .lesson-menu-container {
    width: 100%;
  }

  .splash-screen-img {
    width: 100%; 
    max-width: 350px;
    object-fit: cover;
  }

  .splash-screen-text {
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  

  .spacer {
    height: 20px;
  }

  .big-flex-grid-container{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto, auto);
  }

  .flex-grid-container {
    display: grid;
    grid-template-columns: repeat(auto, auto);
  }

  h2 {
    font-family: "Josefin Sans", Sans-serif;
    font-size: 1.9rem;
    font-weight: 700;
    line-height: 1.5em;
  }

  h3 {
    color: #fff;
    font-family: "Josefin Sans", Sans-serif;
    font-size: 1.8rem;
    font-weight: 200;
  }
  

  p {
    font-family: "Bitter", Sans-serif;
    font-size: 16px !important;
    font-weight: 400;
  }

  .item1 {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 2;
    grid-row-end: 2;
  }

  .item2 {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;
  }
  
  .item3 {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 3;
    grid-row-end: 3;
  }

  .item4 {
    background-color: #000;
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 4;
    grid-row-end: 4;
  }

  .application-item1 {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .application-item2 {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 2;
    grid-row-end: 2;
  }

  .login-item1 {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .login-item2 {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 2;
    grid-row-end: 2;
  }

  .m-content-p {
    padding: 20px;
  }

  .content-p {
    padding: 20px 20px 20px 20px;
  }

  .bg-content-p {
    padding: 20px;
  }

  .bg-content-p-r {
    padding-right: 20px;
  }

  .bg-content-p-l {
    padding-left: 20px;
  }


  .bg-content-p-r {
    padding: 20px;
  }

  .bg-content-p-l {
    padding: 20px;
  }

  .sm-content-p {
    padding: 10px;
  }

  .content-p-t {
    padding-top: 20px;
  }

  .content-p-b {
    padding-bottom: 20px;
  }

  .content-p-l {
    padding-left: 20px;
  }

  .content-p-r {
    padding-right: 20px;
  }

  .nav-menu {
    margin: 0px 10px 0px 10px;
    list-style: none;
    display: flex;
    flex-direction: column;
    width: 800px;
    justify-content: space-evenly;
  }

  .hamburger-btn {
    display: block;
    margin-top: auto;
    margin-bottom: auto;
  }

  .hamburger-display-flex {
    display: flex;
  }

  .hamburger-display-none {
    display: none;
  }

  .array-img-container {
    width: 350px;
    height: 350px;
  }

  .array-img {
    width: 350px;
    height: 350px;
  }

  .array-list {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .sm-p{
    max-width: 350px;
  }


.hamburger-btn-container {
  margin-right: 20px;
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  }

  .instructor-img {
    width: 100%;
    height: 100%;
    max-width: 480px;
    max-height: 480px;
    object-fit: fill;
  }
}

@media only screen and (min-width: 768px) {

  .lesson-menu {
    text-align: center;
    list-style: none;
    display: flex;
    flex-direction: column;
    width: 300px;
    margin-top: 0px;
    padding-left: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .lesson-link {
    margin-left: 36px;
    line-height: 24px;
    color: #fff;
    text-decoration: none;
    margin-top: 12px;
    padding: auto;
    font-family: "Bitter", Sans-serif;
    font-size: 16px !important;
    font-weight: 400;
  }

  .lesson-menu-container {
    width: 300px;
  }

  .spacer {
    height: 60px;
  }

  .big-flex-grid-container{
    width: 100%;
    display: flex; 
    flex-direction: row;
  }

  .flex-grid-container {
    width: 100%;
    max-width: 1140px;
    display: flex; 
    flex-direction: row;
  }

  h1 {
    font-family: "Josefin Sans", Sans-serif;
    font-size: 3.6rem;
    font-weight: 900;
    line-height: 1.5em;
  }

  h2 {
    font-family: "Josefin Sans", Sans-serif;
    font-size: 3.6rem;
    font-weight: 700;
    line-height: 1.5em;
  }

  h3 {
    color: #fff;
    font-family: "Josefin Sans", Sans-serif;
    font-size: 2.3rem;
    font-weight: 200;
  }  

  p {
    font-family: "Bitter", Sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.6em;
  }

  .instructor-img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .instructor-item1 {
    max-width: 640px;
    max-height: 640px;
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;
  }
  .instructor-item2 {
    max-width: 640px;
    max-height: 640px;
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .instructor-item3 {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 2;
    grid-row-end: 2;
  }

  .instructor-item4 {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 2;
  }


  .item1 {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;
  }
  
  .item2 {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .item3 {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 2;
    grid-row-end: 2;
  }
  
  .item4 {
    background-color: #000;
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 2;
  }
  
  .application-item1 {
    width: 50%;
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .application-item2 {
    width: 50%;
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .login-item1 {
    padding-right: 40px;
    width: 60%;
  }

  .login-item2 {
    padding-right: 40px;
    width: 40%;
  }

  .d-s-content-p {
    padding: 20px;
  }

  .d-content-p {
    padding: 60px;
  }

  .d-content-p-t {
    padding-top: 60px;
  }

  .d-content-p-b {
    padding-bottom: 60px;
  }

  .d-content-p-r {
    padding-right: 60px;
  }

  .d-content-p-l {
    padding-left: 60px;
  }

  .sm-content-p {
    padding: 10px;
  }

  .sm-d-content-p-l{
    padding-left: 20px;
  }


  .sm-d-content-p-r{
    padding-right: 20px;
  }

  .lg-d-content-p-r {
    padding-right: 80px;
  }

  .lg-d-content-p-l {
    padding-left: 80px;
  }

  .content-p {
    padding: 60px 60px 60px 60px;
  }

  .bg-content-p {
    padding: 100px;
  }

  .content-p-t {
    padding-top: 60px;
  }

  .content-p-b {
    padding-bottom: 60px;
  }

  .bg-content-p-r {
    padding-right: 100px;
  }

  .bg-content-p-l {
    padding-left: 100px;
  }

  .content-p-l {
    padding-left: 60px;
  }

  .content-p-r {
    padding-right: 60px;
  }

  .nav-menu {
    margin: 0px 10px 0px 10px;
    list-style: none;
    display: flex;
    flex-direction: row;
    width: 800px;
    justify-content: space-evenly;
  }

  .hamburger-btn {
    display: none;
  }

  .hamburger-display-flex {
    display: flex;
  }

  .hamburger-display-none {
    display: flex;
  }

  .array-list {
    display: flex;
    width: 100%;
    flex-direction: row;
  }

  .array-img-container {
    width: 300px;
    height: 300px;
  }

  .array-img {
    width: 300px;
    height: 300px;
  }
  
  .support-item {
    padding: 10px;
    max-width: 300px;
  }


  .hamburger-btn-container {
    margin-top: 10px;
    margin-bottom: 20px;
    margin-right: 20px;
    margin-left: 20px;
    display: flex;
    flex-direction: row;
  }
}


.past-event-item {
  padding: 10px;
  width: 100%;
}

.past-event-item > img {
  height: 100%;
  width: 100%;
  max-height: 300px;
  max-width: 300px;
  object-fit: fill;
}

.past-events {
  width: 100%;
  padding-left: 60px;
  padding-right: 60px;
  display: flex;
  flex-direction: row;
}

.section-past-events {
  padding-top: 60px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.white-bar {
  width: 20px;
  height: 3px;
  background-color: white;
  margin: 4px 0;
}

.login-btn {
  font-family: "Bitter", Sans-serif;
  color: #FFF;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  line-height: 24px;
  margin-left: auto;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-right: 20px;
  padding-left: 20px;
  text-decoration: none;
  background-color: #6627a7;
}


.btn {
  font-family: "Bitter", Sans-serif;
  color: #FFF;
  width: fit-content;
  font-size: 16px;
  line-height: 36px;
  font-weight: 500;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
  padding-left: 20px;
  text-decoration: none;
  background-color: #6627a7;
}

.Logo {
  text-align: right;
  color: #fff;
  font-family: "Bitter",Sans-serif;
  font-size: 16px !important;
  font-weight: 400;
  text-decoration: none;
  line-height: 24px;
  margin-top: 12px;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: 20px;
}

.display-flex {
  display: flex;
}

.display-none {
  display: none;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.input-text {
  margin-bottom: 30px;
  font-family: "Bitter",Sans-serif;
  height: 50px;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 16px;
  line-height: 24px;
}

.center-div {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.abs-center-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bold {
  font-weight: 700;
}

.normal {
  font-weight: 400;
}

.fs-24px{
 font-size: 24px;
}

.w-content {
  width: fit-content
}

.h-content {
  height: fit-content
}

.menu-container {
  background-color: #000;
  position: fixed;
  width: 100%;
  z-index: 100;
}

.menu-gap {
  height:66px !important;
}


.backdrop {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.55); 
  height: 100%;
  width: 100%;
}

.backdrop-pink {
  display: flex;
  flex-direction: column;
  background-color: rgba(241, 107, 138, 0.733);
  height: 100%;
  width: 100%;
}

.faq-h1{
  font-weight: 400;
  font-family: Josefin Sans,sans-serif;
}

.payment-image {
  width: 80px;
  height: 80px;
}

.payment-image-container{
  width: 80px;
  height: 80px;
}

.rounded {
  border-radius: 10px;
}

.payment-table-cell {
  padding: 8px;
  height: 100%;
}

.payment-table-cell {
  padding: 8px;
  height: 100%;
}

.payment-table-row :td(odd) {
  background-color: wheat;
}

.payment-table {
  border: 1px solid wheat;
}