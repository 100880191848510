body {
  color: #FFF;
  margin: 0px;
  height: 100%;
  background-color: #000;
  width: 100% !important;
  height: 100% !important;
}

html{
  font-size: 10px;
  width: 100% !important;
  height: 100% !important;
}

#root {
  width: 100% !important;
  height: 100% !important;
}